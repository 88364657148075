import React, { Component } from "react"
import "./index.css"

const title = "pocker.se - Cross platform app agency, Stockholm, Sweden"
const description =
  "Building apps for iOS, Android and web. From idea to app store. React Native experts. Web3 experts."

class App extends Component {
  render() {
    return (
      <div className="app">
        <section className="center">
          <h1>pocker</h1>
          <h2>Building cross platform apps.</h2>
          <h3>Stockholm, Sweden</h3>
          <p>
            Contact: <a href="mailto:hello@pocker.se">hello@pocker.se</a>
          </p>
        </section>
      </div>
    )
  }
}

export default App

export const Head = () => (
  <>
    <meta charSet="utf-8" />
    <title>{title}</title>
    <meta name="description" content={description} />
    <meta property="og:url" content="https://pocker.se" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
  </>
)
